<template>
  <router-view/>
</template>

<style>
@font-face {
    font-family: FKDisplay;
    src: url("@/fonts/FKDisplay.otf") format("opentype");
}
@font-face {
    font-family: DMSans;
    src: url("@/fonts/DMSans-Regular.ttf") format("truetype");
}
@font-face {
    font-family: Raleway;
    src: url("@/fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
    font-family: Raleway;
    src: url("@/fonts/Raleway-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}
* {
  font-family: "FKDisplay";
}
body{
  margin: 0;
}
</style>

<template>
  <div class="home">
    <div id="p1">
      <div class="wrapper">
        <div class="nav">
            <img class="logo" src="@/assets/img/SITE-WIDE-LOGO-04.svg"/>
            <div class="desktop" v-if="this.language == 'en'">
              <a>HOME</a>
              <a v-scroll-to="'#p2'">ABOUT US</a>
              <a v-scroll-to="'#p3'">SOLUTIONS</a>
              <a v-scroll-to="'#p4'">CLIENTS</a>
              <a v-scroll-to="'#p5'">CONTACT US</a>
              <a @click="openModal">LANGUAGE</a>
              <div class="dropdown" v-if="this.dropdown">
                <span style="color:#2CBFBF">english</span>
                <span @click="changeLanguage('pt')">portuguese</span>
              </div>
            </div>
            <div class="desktop" v-if="this.language == 'pt'">
              <a>HOME</a>
              <a v-scroll-to="'#p2'">SOBRE</a>
              <a v-scroll-to="'#p3'">SOLUÇÕES</a>
              <a v-scroll-to="'#p4'">PARCEIROS</a>
              <a v-scroll-to="'#p5'">CONTATO</a>
              <a @click="openModal">IDIOMA </a>
              <div class="dropdown" v-if="this.dropdown">
                <span @click="changeLanguage('en')">inglês</span>
                <span style="color:#2CBFBF">português</span>
              </div>
            </div>
            <div class="mobile menu">
              <span @click="openMenu" class="material-icons-outlined">menu</span>
            </div>
            <div class="nav-bg" v-if="this.menu">
              <div class="nav-drop" v-if="this.language == 'en' && this.menu">
                <span class="material-icons-outlined" @click="openMenu"> close </span>
                <a>HOME</a>
                <a v-scroll-to="'#p2'">ABOUT US</a>
                <div class="submenu">
                  <a>who we are</a>
                  <a>what we do</a>
                  <a>our purpose</a>
                </div>
                <a v-scroll-to="'#p3'">CLIENTS</a>
                <a v-scroll-to="'#p4'">SOLUTIONS</a>
                <a v-scroll-to="'#p5'">CONTACT US</a>
                <a @click="openModal">
                  <span class="material-icons-outlined arrow">{{ this.arrow }}</span>
                  LANGUAGE</a>
                <div class="dropdown" v-if="this.dropdown">
                  <span style="color:#2CBFBF">english</span>
                  <span @click="changeLanguage('pt')">portuguese</span>
                </div>
              </div>
              <div class="nav-drop" v-if="this.language == 'pt' && this.menu">
                <span class="material-icons-outlined" @click="openMenu"> close </span>
                <a>HOME</a>
                <a v-scroll-to="'#p2'">SOBRE</a>
                <div class="submenu">
                  <a>quem somos</a>
                  <a>o que fazemos</a>
                  <a>nosso proposito</a>
                </div>
                <a v-scroll-to="'#p3'">SOLUÇÕES</a>
                <a v-scroll-to="'#p4'">PARCEIROS</a>
                <a v-scroll-to="'#p5'">CONTATO</a>
                <a @click="openModal">
                  <span class="material-icons-outlined arrow">{{ this.arrow }}</span>
                  IDIOMA </a>
                <div class="dropdown" v-if="this.dropdown">
                  <span @click="changeLanguage('en')">inglês</span>
                  <span style="color:#2CBFBF">português</span>
                </div>
              </div>
            </div>
          </div>
          <img class="line" src="@/assets/img/SITE-WIDE-LINE-03.svg"/>
          <div id="slogan">
            <span v-html="text"></span>
          </div>
        </div>
    </div>
    <div id="p2" v-if="this.language == 'en'">
      <div class="wrapper">
        <h2 style="font-size: 7vh;">about us</h2>
        <div class="text">
          <div>
            <h3 style="margin-top: 0;">WHO WE ARE</h3>
            <p>We are a multidisciplinary team that integrates professionals and 
            scientists from health sciences, exact sciences, computer sciences, 
            communication, and other disciplines.</p>
            <p>We seek to reinvent inefficient industries and processes, 
            transcending through the use of technology.</p>
          </div>

          <div class="center">
            <h3>WHAT WE DO</h3>
            <p>We develop AI-based solutions, uniting state-of-the-art artificial 
            intelligence models and human creativity to solve real problems and 
            implement disruptive projects.</p> 
          </div>
          <div>
            <h3>OUR PURPOSE</h3>
            <p>Allow technology to make a real difference in everyone's lives.</p>
          </div>
          <div>
            <b>Technology as a means to a better life.</b>
          </div>
        </div>
      </div>
    </div>
    <div id="p2" v-if="this.language == 'pt'">
      <div class="wrapper">
        <h2>sobre</h2>
        <div class="text">
          <div>
            <h3 style="margin-top: 0;">QUEM SOMOS</h3>
            <p>Somos um time multidisciplinar que integra profissionais 
              e cientistas das ciências da saúde, ciênciais exatas e da 
              computação, comunicação e outras disciplinas.</p>
            <p>Buscamos reinventar indústrias e processos ineficientes,
            transcendendo através do uso da tecnologia.</p>
          </div>
          <div class="center">
            <h3>O QUE NÓS FAZEMOS</h3>
            <p>Desenvolvemos soluções baseadas em IA, unindo o
            estado-da-arte em modelos inteligência artificial e a
            criatividade humana para resolver problemas reais e
            concretizar projetos disruptivos.</p>
          </div>
          <div>
           <h3>NOSSO PROPÓSITO</h3>
            <p>Permitir que a tecnologia faça diferença real na vida de
            todas as pessoas. </p> 
          </div>
          <div>
            <b>Tecnologia como meio para uma vida melhor.</b>
          </div>
          
        </div>
      </div>


    </div>
    <div id="p3" v-if="this.language == 'en'">
      <div class="wrapper">
        <h2>solutions</h2>
        <div class="text">

          <div class="item" style="margin-top: 0;">
            <img src="@/assets/img/LABORATÓRIO.svg" />
            <div>
              <h3>FOR LABORATORIES</h3>
              <p>Analysis of laboratory tests with AI.</p>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/img/RADAR.svg"/>
            <div>
              <h3>FOR MEDICALLY UNDERSERVED AREAS/POPULATIONS (MUA/P)</h3>
              <p>Population health monitoring with geoprocessing.</p>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/img/ESTETOSCÓPIO.svg"/>
            <div>
              <h3>HEALTH PREDICTION</h3>
              <p>Calculation of risks by condition and by body systems.</p>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/img/AI.svg" />
            <div>
              <h3>GENERATIVE AI</h3>
              <p class="desktop">- books&nbsp;&nbsp;&nbsp;&nbsp; - songs <br />
              - images&nbsp;&nbsp; - videos (clips, animations)</p>
              <p class="mobile">
                - books <br />
                - songs <br />
                - images <br />
                - videos (clips, animations)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="p3" v-if="this.language == 'pt'">
      <div class="wrapper">
        <h2>soluções</h2>
        <div class="text">

          <div class="item" style="margin-top: 0;">
            <img src="@/assets/img/LABORATÓRIO.svg" />
            <div>
              <h3>PARA LABORATÓRIOS</h3>
              <p>Análise de exames laboratoriais com IA.</p>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/img/RADAR.svg"/>
            <div>
              <h3>PARA ÁREAS/ POPULAÇÕES MEDICAMENTE SUBSERVIDAS (MUA/P)</h3>
              <p>Monitoramento de saúde populacional com geoprocessamento.</p>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/img/ESTETOSCÓPIO.svg"/>
            <div>
              <h3>PREDIÇÃO EM SAÚDE</h3>
              <p>Cálculo de riscos por condição e por sistemas do corpo.</p>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/img/AI.svg" />
            <div>
              <h3>GENERATIVE AI</h3>
              <p class="desktop">- livros&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - músicas <br />
              - imagens&nbsp;&nbsp; - vídeos (clipes, animações)</p>
              <p class="mobile">
                - livros <br />
                - músicas <br />
                - imagens <br />
                - vídeos (clipes, animações)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div id="p4">
      <div class="wrapper extended">
        <h2 v-if="this.language == 'en'">clients</h2>
        <h2 v-if="this.language == 'pt'">parceiros</h2>
        <div class="text">
          <img src="@/assets/img/AREA23.png" style="margin-top: 0; max-height: 20vh;"/>
          <img style="max-height: 20vh;" src="@/assets/img/BIZSYS.png" />
          <img style="max-height: 20vh;" src="@/assets/img/RITMIKA.png" />
          <img style="max-height: 15vh;" src="@/assets/img/WPP_2020_logo-branco-01.svg"/>
          <img style="max-height: 15vh;" src="@/assets/img/group-link.svg" />
          <img style="max-height: 15vh;" src="@/assets/img/RIOZINHO.svg" />
          <img style="max-height: 15vh;" src="@/assets/img/MP-RS.svg" />
          
        </div>
      </div>
    </div>
    <div id="p5">
      <h2 v-if="this.language == 'en'">contact</h2>
      <h2 v-if="this.language == 'pt'">contato</h2>
      <div style="margin: auto 0;display: inline-flex;">
        <span class="material-icons-outlined"> email </span>
        <a href="mailto:contact@widelabs.com.br">contact@widelabs.com.br</a>
      </div>
      
    </div>
  </div>
</template>

<style scoped>
  .mobile{
    display: none!important;
  }
  #p1{
    height: 100vh;
    width: 100%;
    background-image: url('@/assets/img/SITE-WIDE-BG.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .wrapper{
    width: 132vh;
    max-width: 100%;
    margin: 0 auto;
    height: 100vh;
  }
  .extended{
    height: 100vh;
  }
  .nav{
    padding: 2vh 6.5vh;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  .logo{
    height: 8vh;
  }

  .nav div{
    padding-top: 2vh;
    width: 60vh;
    justify-content: space-between;
    display: flex;
    font-size: 1.6vh;
    font-weight: normal;
  }
  .nav a{
    color: white;
    cursor: pointer;
    margin: auto 0;
  }

  #p1 .dropdown{
    position: absolute;
    top: 7vh;
    right: 6.5vh;
    display: grid;
    text-align: end;
    width: fit-content;
    font-size: 1.5vh;
    gap: 1vh;
    color: white;
    font-family: "DMSans";
  }
  .nav a:hover,
  .dropdown span:hover{
    color: #2CBFBF;
    cursor: pointer;
  }

  .line{
    height: 100vh;
    position: relative;
    margin-top: -13vh;
  }
  #slogan{
    margin-top: -7%;
    transform: translateY(-100%);
    text-align: end;
    margin-right: 5%;
  }
  #slogan span{
    font-size: 6vh;
    color: white;
    letter-spacing: 2px;
    line-height: 1.2;
  }
  #p2 .wrapper,
  #p3 .wrapper,
  #p4 .wrapper{
    display: flex;
    vertical-align: middle;
  }
  #p2 h2,
  #p3 h2,
  #p4 h2{
    min-width: 30%;
    margin: auto;

    color: #BF2D49;
    font-size: 9vh;
    line-height: 0.9;
    letter-spacing: 1vh;
    text-align: center;
    font-family: "DMSans";
  }
  #p2 .text,
  #p3 .text{
    margin: auto;
    padding: 5%;
    padding-right: 7%;
  }
  #p2 h3,
  #p3 h3{
    color: #2CBFBF;
    font-weight: normal;
    font-size: 3.4vh;
    letter-spacing: 0.2vh;

    margin-top: 7vh;
    margin-bottom: -1vh;
  }
  #p2 p, #p2 b,
  #p3 p, #p3 b{
    font-family: 'Raleway';
    font-size: 2.4vh;
    letter-spacing: 0.2vh;
    color: #666666;

    text-align: justify;
  }

  #p2 b,
  #p3 b{
    text-align: left;
    margin-top: 7vh;
    display: flex;
    letter-spacing: 0.1vh;
  }

  #p3{
    height: 100vh;
    background-image: url('@/assets/img/bg-vermelho.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  #p3 h2{
    color: white;
    font-size: 8vh;
  }
  #p3 h3{
    color: white;
    margin: 0;
  }
  #p3 p{
    color: white;
    margin-bottom: 0;
    text-align: left;
  }
  #p3 .item{
    display: flex;
    margin-top: 6vh;
  } 
  #p3 .item div{
    margin: auto 0;
  }
  #p3 img{
    height: 12vh;
    margin: auto 0;
    margin-right: 6%;
  }
  #p4{
    height: 150vh;
    background-image: url('@/assets/img/SITE-WIDE-BG.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  #p4 h2{
    color: #2CBFBF;
    font-size: 8vh;
  }
  #p4 .text{
    display: block;
    text-align: center;
    margin: auto;
  }
  #p4 img{
    width: 60vh;
    margin-top: 5vh;
  }
  #p5{
    display: flex;
    justify-content: center;
  }
  #p5 h2{
    color: #BF2D49;
    font-size: 4vh;
    margin-right: 2vh;
    letter-spacing: 0.5vh;
  }
  #p5 a{
    text-decoration: none;
    color: #666666;
    font-size: 2.4vh;
    letter-spacing: 0.2vh;
    margin: auto 0;
  }
  #p5 span{
    color: #2CBFBF;
    font-size: 5vh;
    margin: auto 1vh;
  }
  @media only screen and (max-width: 840px) {
    .desktop{
      display: none!important;
    }
    .mobile{
      display: block!important;
    }
    .line{
      overflow: hidden;
      height: auto;
      width: 100%;
    }
    #slogan{
      padding-bottom: 10%;
    }
    #slogan span{
      font-size: 10vw;
    }
    .menu{
      color: white;
      text-align: end;
    }
    .menu span{
      font-size: 4vh;
      line-height: 5vh;
    }
    #p1 .nav-bg{
      background-color: rgb(255, 255, 255, 0.3);
      height: 100vh;
      width: 100vw;
      position: fixed;
      left: 0;
      top: 0;
    }
    #p1 .nav-drop{
      position: absolute;
      display: grid;
      width: fit-content;
      height: fit-content;
      text-align: end;
      background-color: #181815;
      color: white;
      font-size: 3vh;
      letter-spacing: 0.3vh;
      gap: 4vh;
      right: 6%;
      padding: 3vh;
      padding-left: 7vh;
    }
    #p1 .nav-drop span{
      font-size: 4vh;
      cursor: pointer;
      width: fit-content;
      margin-left: auto;
    }
    #p1 .nav-drop > span:hover{
      background-color: #666666;
      border-radius: 100%;
    }
    #p1 .arrow{
      vertical-align: middle;
      line-height: 2vh;
    }
    #p1 .submenu{
      font-size: 3vh;
      display: grid;
      margin-left: auto;
      width: fit-content;
      padding-top: 0;
    }
    #p1 .submenu a{
      color: #2CBFBF;
    }
    #p1 .dropdown{
      position: initial;
      padding-top: 0;
      gap: 0.5vh;
      margin-left: auto;
      margin-top: -3vh;
    }
    #p1 .dropdown span{
      font-size: 3vh;
      color: #2CBFBF;
    }
    #p1 .dropdown span:hover{
      background-color: transparent;
    }
    #p1{
      height: 115vw;
    }
    .center{
      background-color: #2CBFBF;
      padding: 10% 10%;
      margin-top: 10%;
      margin-left: -12%;
      margin-right: -14%;
    }
    .center h3, .center p{
      color: white!important;
    }
    .center h3{
      margin-top: 0!important;
    }
    .center p{
      margin-bottom: 0!important;
    }
    #p2 .wrapper,
    #p3 .wrapper,
    #p4 .wrapper{
      display: block;
      height: fit-content;
      width: fit-content;
      padding: 10% 5%;
    }
    #p2 h2,
    #p3 h2,
    #p4 h2{
      margin-bottom: 3vh;
    }
    #p2 p, #p2 b, #p2 h3,
    #p3 p, #p3 h3,
    #p4 p, #p4 h3{
      text-align: center;
      display: block;
    }

    #p3{
      height: fit-content;
    }

    #p3 .item{
      display: block;
      text-align: center;
    }
    #p3 img{
      margin-right: 0;
      margin-bottom: 3vh;
    }

    #p4{
      height: fit-content;
      padding: 5% 10%;
    }
    #p4 h2{
      margin-bottom: 5vh;
    }
    #p4 img{
      width: 70vw;
      margin-bottom: 2vh;
    }

    #p5{
      display: block;
      text-align: center;
      padding-bottom: 5vh;
    }
    #p5 h2{
      font-size: 5vh;
    }
  }

</style>

<script>
export default {
  name: 'HomeView',
  mounted(){
    let aux = Math.floor(Math.random() * this.slogans.length )
    this.text = this.slogans[aux]
  },
  data(){ return {
    slogans: ["We enjoy solving <br> unsolvable puzzles.",
              "Call us when the <br> others give up.",
              "State-of-the-Art <br> Artificial intelligence <br> builders to solve <br> unsolvable problems.",
              "Artificial Intelligence. <br> Human passion."],
    text: "",
    language: "en",
    dropdown: false,
    menu: false,
    arrow: 'arrow_drop_down'
  }},
  methods: {
    changeLanguage(lang){
      this.language = lang;
      this.dropdown = false;
      this.menu = false;
      this.arrow = 'arrow_drop_down'
    },
    openModal(){
      this.dropdown = !this.dropdown
      this.arrow = this.arrow == 'arrow_drop_down'? 'arrow_drop_up' : 'arrow_drop_down'
    },
    openMenu(){
      this.menu = !this.menu
    }
  }
}
</script>

<template>
    <div class="error">
    <div class="text">
        <h2 class="errorT">error</h2>
        <h1>404</h1>
        <h2 class="pageT">page not found :(</h2>
    </div>
    <div class="line">
       <img src="../assets/img/BLACK-LINE-03.svg"/> 
    </div>
    </div>
</template>
<style scoped>
    .error{
        background-color: #BF2D49;
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }
    .line{
        margin-top: -13vh;
        position: absolute;
        overflow: hidden;
        width: 78%;
        height: 113vh;
        left: 22%;
    }
    .line img{
        height: 126vh;
    }
    .text{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        width: 100%;
        justify-content: center;

    }
    .text h1{
        color: #2CBFBF;
        font-size: 22vh;
        margin-top: 5vh;
        margin-bottom: 0;
    }
    .text h2{
        color: white;
        font-size: 7vh;
        margin: auto 3vh;
        padding: 0 3vh;
    }
    @media only screen and (max-width: 840px) {
        .line{
            left: 0;
            width: 100%;
            text-align: center;
        }
        .line img{
            min-height: 133vh;
            margin: auto;
            position: absolute;
            left: 45%;
            top: 55%;
            transform: translateY(-50%) translateX(-50%);
            object-fit: cover;
        }
        .text{
            display: block;
            text-align: center;
        }
        .error h2{
            font-size: 7vw;
        }
        .error .errorT{
            margin-bottom: 35%;
        }
        .error .pageT{
            margin-top: 35%;
        }
    }
</style>
<script>
export default {
  name: 'NotFound'
}
</script>